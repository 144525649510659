import React, { useEffect, useState } from "react";
import { OpeningHours, Restaurant } from "../types";
import RestaurantFeature from "./RestaurantFeature";
import { BsLightningCharge, BsCarFront } from "react-icons/bs";
import { DateTime, Duration } from "luxon";
import { isDateTimeInsideOpeningHours } from "../helpers/openingHours";
import { motion } from "framer-motion";
import { useRestaurantMapDispatch } from "../RestaurantMapContext";
import useLocalization from "../../Common/Localization/UseLocalization";

interface RestaurantListItemProps {
  restaurant: Restaurant;
}

const RestaurantListItem = ({
  restaurant,
  ...props
}: RestaurantListItemProps) => {
  const [today, setToday] = useState<OpeningHours | null>(null);
  const dispatch = useRestaurantMapDispatch();
  const localization = useLocalization();

  useEffect(() => {
    // TODO: Handle this on the Backend?
    setToday(
      restaurant.openingHours
        .filter((o) => o.closing && o.opening)
        .find((o) => isDateTimeInsideOpeningHours(DateTime.now(), o)) ?? null
    );
  }, [restaurant.openingHours]);

  // TODO: Are blank opening/closing = closed?
  // TODO: "Open today" might be confusing for opening hours that extend into next day?
  return (
    <motion.li
      className="map-restaurant-list__list-item"
      onTap={() => dispatch?.setActiveRestaurant(restaurant)}
      initial={{
        boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.15)",
        zIndex: 1,
      }}
      whileTap={{
        scale: 0.95,
        boxShadow: "0px 0px 0px rgba(0, 0, 0, 0.15)",
      }}
      whileHover={{
        scale: 1.04,
        zIndex: 1000,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
      }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
    >
      <div className="map-restaurant-list__list-item__main">
        <div>
          <div className="map-restaurant-list__list-item__name">
            {restaurant.name}
          </div>
          <div className="map-restaurant-list__list-item__address">
            {restaurant.streetAddress}
          </div>
        </div>
        <div>
          <div>
            {localization("/pages/restaurantlistpage/opening-hours-today")}
          </div>
          <div className="map-restaurant-list__list-item__opening-hours">
            {today ? `${today.opening} – ${today.closing}` : "—"}
          </div>
        </div>
      </div>
      <div className="map-restaurant-list__list-item__sub">
        {restaurant.carChargers.length > 0 && (
          <RestaurantFeature
            label={localization("/pages/restaurantlistpage/ev-charger")}
            icon={BsLightningCharge}
          />
        )}
        {restaurant.hasDriveIn && (
          <RestaurantFeature
            label={localization("/pages/restaurantlistpage/drive-in")}
            icon={BsCarFront}
          />
        )}
      </div>
    </motion.li>
  );
};

export default RestaurantListItem;
