/**
* Toggles visibility of collapse
*/
class Collapse {
  static init() {
    const collapse = document.querySelectorAll('.js-collapse');

    if (collapse.length === 0) {
      return;
    }

    const collapseTriggers = document.querySelectorAll('.js-collapse-trigger');

    Object.keys(collapseTriggers).forEach((index) => {
      collapseTriggers[index].addEventListener('click', (e) => {
        const link = e.currentTarget;
        const expanded = link.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';
        const icon = link.querySelector('svg');

        // toggle arrow icon
        if (expanded === 'true') {
          icon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up icon"><polyline points="18 15 12 9 6 15"></polyline></svg>';
        } else {
          icon.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>';
        }

        link.setAttribute('aria-expanded', expanded);
        document.querySelector(link.getAttribute('href')).classList.toggle('show');

        e.preventDefault();
      });
    });
  }
}

export default Collapse;
