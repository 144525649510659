import Utilities from './utilities';

class LanguageModal {
  static init() {
    /**
    * Opens the language selector modal
    */

    const modal = document.querySelector('.js-modal');

    if (!modal || modal.length === 0) {
      return;
    }

    const blackout = document.querySelector('.js-body-blackout');
    const cookieName = 'MaxSelectedLanguage';
    const languageSelectors = document.querySelectorAll('.a-current-language');
    const enableLanguageOverlay = modal.getAttribute('data-enable-language-overlay');

    if (document.cookie.indexOf(cookieName) === -1 && enableLanguageOverlay !== null) {
      this.showModal(modal, blackout, cookieName);
    }

    languageSelectors.forEach((element) => {
      element.addEventListener('click', (event) => {
        event.stopPropagation();
        this.showModal(modal, blackout, cookieName);
      });
    });
  }

  static showModal(modal, blackout, cookieName) {
    const body = document.querySelector('body');

    modal.classList.add('is-visible');
    blackout.classList.add('is-open');
    body.classList.add('modal-open');

    body.addEventListener('click', () => {
      modal.classList.remove('is-visible');
      blackout.classList.remove('is-open');
      body.classList.remove('modal-open');

      Utilities.setCookie(cookieName, true, 30);
    });
  }
}

export default LanguageModal;
