/**
 * Off canvas navigation
 */
const currentClassName = "js-current-nav-item";

class SiteNav {
  static init() {
    const toggler = document.querySelector(".js-navbar-toggler");
    const navItemsWithChildren = document.querySelectorAll(
      ".m-navbar-lg__nav-item--has-children"
    );
    const navItemsWithoutChildren = document.querySelectorAll(
      ".m-navbar-lg__nav-item:not(.m-navbar-lg__nav-item--has-children)"
    );
    const brandLink = document.querySelector(".m-navbar-lg__brand");

    const nav = document.querySelector(".m-navbar-sm");
    const overlay = document.querySelectorAll(".m-navbar__overlay");
    const subNavToggle = document.querySelectorAll(".js-toggle-sub-nav");
    const curtain = document.querySelector(".js-navbar-curtain");
    const body = document.querySelector("body");

    toggler.addEventListener("click", (e) => {
      e.stopPropagation();
      body.classList.toggle("nav-open");
      nav.classList.toggle("is-active");
    });

    [...overlay].forEach((item) => {
      item.addEventListener("click", (e) => {
        e.stopPropagation();
        body.classList.remove("nav-open");
        body.classList.remove("subnav-open");
        nav.classList.remove("is-active");
      });
    });

    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        this.closeCurrentSubNav();
      }
    });

    [...navItemsWithoutChildren].forEach((item) => {
      item
        .querySelector(".m-navbar-lg__nav-link")
        .addEventListener("mouseover", () => this.closeCurrentSubNav(), true);
    });

    [...navItemsWithChildren].forEach((item) => {
      item
        .querySelector(".m-navbar-lg__nav-link")
        .addEventListener(
          "click",
          (e) => {
            this.toggleSubNav(item);
          },
          true
        );
    });

    [...subNavToggle].forEach((button) => {
      button.addEventListener(
        "click",
        (e) => {
          this.toggleSubNav(button.parentElement.parentElement);
        },
        true
      );
    });

    function closeCurtain(e, nav) {
      e.stopPropagation();
      nav.closeCurrentSubNav();
      curtain.classList.remove("m-navbar-lg__curtain--active");
      curtain.classList.add("m-navbar-lg__curtain--closing");
      setTimeout(() => {
        curtain.classList.remove("m-navbar-lg__curtain--closing");
      }, 250);
    }

    curtain.addEventListener("click", (e) => {
      closeCurtain(e, this);
    });

    // TODO: Set aria-expanded=false on all subnavs

    this.detectScrollDirection();
  }

  static toggleSubNav(container) {
    const thisIsCurrent = container.classList.contains(currentClassName);

    // TODO: Update aria-expanded
    const body = document.querySelector("body");
    if (!thisIsCurrent) {
      this.closeCurrentSubNav();
      this.showSubNav(container);
      body.classList.add("subnav-open");
    } else {
      body.classList.remove("subnav-open");
      this.closeCurrentSubNav();
    }
  }

  static showSubNav(container) {
    const thisIsCurrent = container.classList.contains(currentClassName);
    const curtain = document.querySelector(".js-navbar-curtain");
    curtain.classList.add("m-navbar-lg__curtain--active");

    if (!thisIsCurrent) {
      this.closeCurrentSubNav();
      if (container.classList.contains("m-navbar-lg__nav-item")) {
        container.classList.add(currentClassName, "m-navbar-lg__nav-item--current");
      } else {
        container.classList.add(currentClassName, "m-navbar-sm__nav-item--current");
      }
    }
  }

  static closeCurrentSubNav() {
    const oldCurrentContainer = document.querySelector(`.${currentClassName}`);

    if (oldCurrentContainer) {
      if (oldCurrentContainer.classList.contains("m-navbar-lg__nav-item")) {
        oldCurrentContainer.classList.remove(
          currentClassName,
          "m-navbar-lg__nav-item--current"
        );
        oldCurrentContainer.classList.add("m-navbar-lg__nav-item--closing");
        setTimeout(() => {
          oldCurrentContainer.classList.remove("m-navbar-lg__nav-item--closing");
        }, 500);
      } else {
        oldCurrentContainer.classList.remove(
          currentClassName,
          "m-navbar-sm__nav-item--current"
        );
      }
    }
  }

  static detectScrollDirection() {
    this.totalScrollDelta = 0;

    window.addEventListener("scroll", () => {
      // Ignore negative values (happens for example on iOS safari when scrolling up "beyond" top)
      const scrollY = Math.max(0, window.pageYOffset || document.documentElement.scrollTop);
      const navbar = document.querySelector(".m-navbar");
      const delta = scrollY - (this.lastScroll ?? 0);

      // This makes sure that you can scroll a small amount without
      // triggering the menu showing/hiding
      if (
        (this.totalScrollDelta > 0 && delta < 0) ||
        (this.totalScrollDelta < 0 && delta > 0)
      ) {
        this.totalScrollDelta = 0;
      } else {
        this.totalScrollDelta += delta;
      }

      if (navbar) {
        if (this.totalScrollDelta < -64) {
          navbar.classList.remove("m-navbar--hide");
        } else if (this.totalScrollDelta > 64) {
          navbar.classList.add("m-navbar--hide");
        }
      }

      this.lastScroll = scrollY;
    });
  }
}

export default SiteNav;
