import React from "react";
import {
  useRestaurantMap,
  useRestaurantMapDispatch,
} from "../RestaurantMapContext";
import RestaurantListItem from "./RestaurantListItem";
import { AnimatePresence, motion } from "framer-motion";
import { Position, Restaurant } from "../types";
import haversine from "haversine";
import useLocalization from "../../Common/Localization/UseLocalization";

interface RestaurantListProps {}

function getDistance(position: Position, restaurant: Restaurant) {
  const pos = {
    latitude: position.lat,
    longitude: position.lng,
  };

  const distance = haversine(pos, {
    latitude: parseFloat(restaurant.latitude),
    longitude: parseFloat(restaurant.longitude),
  });

  return distance;
}

const RestaurantList = ({}: RestaurantListProps) => {
  const localization = useLocalization();
  const { restaurants, filteredRestaurants, geolocation, focusPosition } =
    useRestaurantMap();

  const restaurantWithDistances = focusPosition
    ? filteredRestaurants
        .filter(
          (r) =>
            !isNaN(parseFloat(r.latitude)) && !isNaN(parseFloat(r.longitude))
        )
        .map((r) => ({
          ...r,
          distance: getDistance(focusPosition, r),
        }))
    : null;

  const restaurantWithoutDistances = focusPosition
    ? filteredRestaurants.filter(
        (r) => isNaN(parseFloat(r.latitude)) || isNaN(parseFloat(r.longitude))
      )
    : null;

  const sortedRestaurants = restaurantWithDistances
    ? [
        ...restaurantWithDistances.sort((a, b) => a.distance - b.distance),
        ...(restaurantWithoutDistances ?? []),
      ]
    : filteredRestaurants.sort();

  const restaurantList = sortedRestaurants.map((r, i) => (
    <RestaurantListItem key={r.link} restaurant={r} />
  ));

  return (
    <>
      <div className="map-window__layout">
        <span>
          {filteredRestaurants.length == restaurants.length
            ? localization(
                "/pages/restaurantlistpage/showing-all-restaurants"
              )?.replace("%TOTAL%", restaurants.length.toString())
            : localization(
                "/pages/restaurantlistpage/showing-filtered-restaurants"
              )
                ?.replace("%TOTAL%", restaurants.length.toString())
                .replace("%FILTERED%", filteredRestaurants.length.toString())}
        </span>
      </div>
      <ul className="map-restaurant-list__list">{restaurantList}</ul>
      <div className="map-restaurant-list__list__after"></div>
    </>
  );
};

export default RestaurantList;
