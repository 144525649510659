import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from "react";
import {
  MarkerClusterer,
  SuperClusterAlgorithm,
} from "@googlemaps/markerclusterer";
import { renderer } from "../helpers/cluster";
import IsMobile from "../../Common/helpers/IsMobile";
import {
  useRestaurantMap,
  useRestaurantMapDispatch,
} from "../RestaurantMapContext";
import { Restaurant } from "../types";
import haversine from "haversine";
import { MdMyLocation } from "react-icons/md";

const pinSvg = `<svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.66621 0.256287C3.82524 0.256287 0.710655 3.41259 0.677734 7.31573C0.688708 8.68337 1.09258 9.96064 1.7807 11.0328L7.12021 18.5215C7.27304 18.7924 7.46976 18.9291 7.66621 18.9291C7.8656 18.9291 8.06473 18.7924 8.21221 18.5215L13.5517 11.0354C14.2396 9.96064 14.6437 8.68337 14.6544 7.31573C14.6247 3.41259 11.5099 0.256287 7.66621 0.256287Z" fill="#E83D3D"/>
</svg>`;

const focusSvg = `<svg width="78" height="104" viewBox="0 0 78 104" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M39.0007 0C17.5653 0 0.183719 17.5793 0 39.3181C0.0612397 46.9353 2.31516 54.0492 6.15534 60.0204L35.9537 101.73C36.8066 103.238 37.9044 104 39.0007 104C40.1135 104 41.2248 103.238 42.0478 101.73L71.8462 60.0354C75.6848 54.0492 77.9403 46.9353 78 39.3181C77.8342 17.5793 60.4511 0 39.0007 0Z" fill="#E83D3F"/>
</svg>`;

export type MarkerTuple = [
  Restaurant,
  google.maps.marker.AdvancedMarkerElement
];

function Map() {
  const ref = useRef<HTMLDivElement>(null);
  const [markers, setMarkers] = useState<MarkerTuple[]>([]);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markerClusterer, setMarkerClusterer] =
    useState<MarkerClusterer | null>(null);
  const [geolocationMarker, setGeolocationMarker] =
    useState<google.maps.marker.AdvancedMarkerElement | null>(null);

  const {
    restaurants,
    activeRestaurant,
    filteredRestaurants,
    geolocation,
    geolocationPosition,
    focusPosition,
    startCoordinates,
  } = useRestaurantMap();
  const dispatch = useRestaurantMapDispatch();

  useEffect(() => {
    const createMarker = async () => {
      const { AdvancedMarkerElement, PinElement } =
        (await google.maps.importLibrary(
          "marker"
        )) as google.maps.MarkerLibrary;

      const markerElement = document.createElement("div");

      markerElement.className = "map-marker__current";

      const marker = new AdvancedMarkerElement({
        map: null,
        position: { lat: 0, lng: 0 },
        content: markerElement,
      });
      setGeolocationMarker(marker);
    };
    createMarker();
  }, []);

  const zoom = startCoordinates?.zoom ?? 7;
  const center = {
    lat: startCoordinates?.lat ?? 60.1282,
    lng: startCoordinates?.lng ?? 18.6435,
  };

  const filteredIds = filteredRestaurants.map((r) => r.link);
  const visibleMarkerIds = markers
    .map((m) => m[0].link)
    .filter((id) => filteredIds.includes(id));

  const onRestaurantClick = (r: Restaurant) => {
    dispatch?.setActiveRestaurant(r);
  };

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    const map = new window.google.maps.Map(ref.current, {
      center,
      zoom,
      clickableIcons: false,
      mapId: "226b09c969746719",
      disableDefaultUI: true,
      zoomControl: true,
      fullscreenControl: true,
    });

    setMap(map);

    async function initMap() {
      const { AdvancedMarkerElement, PinElement } =
        (await google.maps.importLibrary(
          "marker"
        )) as google.maps.MarkerLibrary;

      const newMarkers = restaurants
        .filter(
          (r) =>
            !isNaN(parseFloat(r.latitude)) && !isNaN(parseFloat(r.longitude))
        )
        .map((restaurant) => {
          const position = {
            lat: parseFloat(restaurant.latitude),
            lng: parseFloat(restaurant.longitude),
          };

          const glyphImg = document.createElement("img");
          glyphImg.src = "/build/images/map/pin.svg";
          const marker = new AdvancedMarkerElement({
            map,
            position,
            title: restaurant.name,
            content: glyphImg,
          });

          marker.addListener("click", () => {
            onRestaurantClick(restaurant);
          });

          return [restaurant, marker] as MarkerTuple;
        });

      setMarkers(newMarkers);

      setMarkerClusterer(
        new MarkerClusterer({
          markers: markers.map((m) => m[1]),
          map,
          algorithm: new SuperClusterAlgorithm({ radius: 100, maxZoom: 13 }),
          renderer,
        })
      );
    }

    initMap();
  }, []);

  useEffect(() => {
    if (!map || !activeRestaurant) {
      return;
    }

    const position =
      !isNaN(parseFloat(activeRestaurant.latitude)) &&
      !isNaN(parseFloat(activeRestaurant.longitude))
        ? {
            lat: parseFloat(activeRestaurant.latitude),
            lng: parseFloat(activeRestaurant.longitude),
          }
        : null;

    if (!position) {
      return;
    }

    map.setZoom(Math.max(15, map.getZoom() ?? zoom));
    map.panTo(position);

    if (IsMobile()) {
      map.panBy(0, -200);
    } else {
      map.panBy(-150, 0);
    }
  }, [activeRestaurant]);

  useEffect(() => {
    // Clear clusters
    if (markerClusterer) {
      markerClusterer.clearMarkers();
      markerClusterer.setMap(map);
    }

    // Filter markers
    markers.forEach(([data, marker]) => {
      marker.map = visibleMarkerIds.indexOf(data.link) >= 0 ? map : null;
    });

    // Recalculate clusters
    if (markerClusterer) {
      markerClusterer.addMarkers(
        markers
          .filter(([data, marker]) => marker.map != null)
          .map(([data, marker]) => marker)
      );
    }
  }, [markers, visibleMarkerIds, markerClusterer]);

  useEffect(() => {
    if (geolocation && map) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          dispatch?.setGeolocationPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          dispatch?.setFocusPosition({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        () => {
          dispatch?.setGeolocation(false);
        }
      );
    }
  }, [geolocation, map]);

  // Update geolocation marker
  useEffect(() => {
    if (geolocationMarker && geolocationPosition) {
      geolocationMarker.map = map;
      geolocationMarker.position = geolocationPosition;
    } else if (geolocationMarker) {
      geolocationMarker.map = null;
    }
  }, [geolocation, geolocationMarker, geolocationPosition]);

  useEffect(() => {
    if (!focusPosition) {
      return;
    }

    const closest = filteredRestaurants
      .map((r) => ({
        position: { lat: +r.latitude, lng: +r.longitude },
        distance: haversine(
          { latitude: +r.latitude, longitude: +r.longitude },
          { latitude: focusPosition.lat, longitude: focusPosition.lng }
        ),
      }))
      .sort((a, b) => a.distance - b.distance);

    const closestPos =
      closest.length >= 1 ? closest[0].position : focusPosition;

    const bounds = new google.maps.LatLngBounds();
    bounds.extend(focusPosition);
    bounds.extend(closestPos);
    bounds.extend(closestPos);
    map?.fitBounds(bounds, 32);
    map?.setZoom(Math.min(16, map.getZoom() ?? 16));
  }, [focusPosition]);

  //useEffect(() => {
  //  if (map && (center.lat != lastCenter.lat || center.lng != lastCenter.lng)) {
  //    map.panTo(center);
  //    map.setZoom(Math.min(14, map.getZoom()));
  //  }
  //}, [map, center]);

  return <div ref={ref} style={{ height: "100%", width: "100%" }}></div>;
}

export default Map;
