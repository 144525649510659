import React, { useEffect, useState } from "react";
import FilterControls from "./FilterControls";
import RestaurantList from "./RestaurantList";
import {
  useRestaurantMap,
  useRestaurantMapDispatch,
} from "../RestaurantMapContext";
import { AnimatePresence, motion } from "framer-motion";
import EvFilterSettings from "./EvFilterSettings";
import WindowHeading, { WindowHeadingLink } from "./WindowHeading";

interface RestaurantListViewProps {
  title: string;
  links?: WindowHeadingLink[];
}

function RestaurantListView({ title, links }: RestaurantListViewProps) {
  const [showEvFilterSettings, setShowEvFilterSettings] = useState(false);
  const { filters } = useRestaurantMap();

  useEffect(() => {
    if (showEvFilterSettings && filters.ev.hasCharger == false) {
      setShowEvFilterSettings(false);
    }
  }, [showEvFilterSettings, filters.ev]);

  return (
    <>
      <WindowHeading title={title} links={links} />
      <FilterControls
        onEvClick={() => setShowEvFilterSettings(!showEvFilterSettings)}
      />
      <RestaurantList />
      <EvFilterSettings
        show={filters.ev.hasCharger == true && showEvFilterSettings}
        onClose={() => setShowEvFilterSettings(false)}
      />
    </>
  );
}

export default RestaurantListView;
