/**
* Adds functionality to contact forms
*/
class Forms {
  static init() {
    const form = document.querySelector('.js-form');
    const formButton = document.querySelector('.js-form-button');
    const formContainer = document.querySelector('.js-form-container');
    const contactFormSubject = document.querySelector('.js-contact-form-subject');
    const franchiseFormCountry = document.querySelector('.js-franchise-form-country');

    if (!formContainer || formContainer.length === 0) {
      return;
    }

    // Checks for the contact page form
    if (contactFormSubject && contactFormSubject.length !== 0) {
      contactFormSubject.addEventListener('change', this.toggleRestaurantFields, true);
    }

    // Checks for the contact page form
    if (franchiseFormCountry && franchiseFormCountry.length !== 0) {
      const locations = window.countryDetails;
      franchiseFormCountry.addEventListener('change', () => this.populateDetailsField(locations, franchiseFormCountry), true);
    }

    // Enable reCaptcha and expose callBacks
    if (formButton && formButton.length !== 0) {
      window.reCaptchaChecked = false;
      window.reCaptchaCheckCallback = this.reCaptchaCheckCallback;
      window.reCaptchaExpiredCallback = this.reCaptchaExpiredCallback;
    }

    if (form && form.length !== 0) {
      form.addEventListener('submit', this.reCaptchaValidate);
    }

    this.toggleRestaurantFields();
  }

  static toggleRestaurantFields() {
    const restaurantInputContainer = document.querySelector('.js-form-restaurants');
    const receiptInputContainer = document.querySelector('.js-form-receipt');
    const visitTimeInputContainer = document.querySelector('.js-form-visit-time');
    const visitDateInputContainer = document.querySelector('.js-form-visit-date');
    const paymentAmountInputContainer = document.querySelector('.js-form-payment-amount');
    const selIndex = this.selectedIndex;
    if (!this.selectedIndex || !this.options || this.options.length === 0) {
      return;
    }

    const { showRestaurants, showReceiptNumber, showVisitTime, showPaymentAmount } = this.options[selIndex].dataset;

    if (showRestaurants === 'true') {
      restaurantInputContainer.classList.remove('d-none');
      document.getElementById('Restaurant').disabled = false;
    } else {
      restaurantInputContainer.classList.add('d-none');
      document.getElementById('Restaurant').disabled = true;
    }

    if (showReceiptNumber === 'true') {
      receiptInputContainer.classList.remove('d-none');
      document.getElementById('ReceiptNumber').disabled = false;
    } else {
      receiptInputContainer.classList.add('d-none');
      document.getElementById('ReceiptNumber').disabled = true;
    }

    if (showVisitTime === 'true') {
      visitTimeInputContainer.classList.remove('d-none');
      visitDateInputContainer.classList.remove('d-none');
      document.getElementById('VisitTime').disabled = false;
      document.getElementById('VisitDate').disabled = false;
    } else {
      visitTimeInputContainer.classList.add('d-none');
      visitDateInputContainer.classList.add('d-none');
      document.getElementById('VisitTime').disabled = true;
      document.getElementById('VisitDate').disabled = true;
    }

    if (showPaymentAmount === 'true') {
      paymentAmountInputContainer.classList.remove('d-none');
      document.getElementById('PaymentAmount').disabled = false;
    } else {
      paymentAmountInputContainer.classList.add('d-none');
      document.getElementById('PaymentAmount').disabled = true;
    }
  }

  static populateDetailsField(locations, franchiseFormCountry) {
    const currentValue = franchiseFormCountry.options[franchiseFormCountry.selectedIndex].value;
    const detailOptions = locations[currentValue];

    if (locations[currentValue]) {
      const detailsSelect = document.querySelector('.js-franchise-form-country-details');
      const firstOption = franchiseFormCountry.querySelector('.js-franchise-form-first-option');

      detailsSelect.disabled = false;
      detailsSelect.innerHTML = '';

      if (firstOption) {
        firstOption.parentNode.removeChild(firstOption);
      }

      detailOptions.forEach((detailOption) => {
        const optionEl = document.createElement('option');
        optionEl.text = detailOption;
        optionEl.value = detailOption;
        detailsSelect.appendChild(optionEl);
      });
    }
  }

  static reCaptchaValidate(e) {
    if (!window.reCaptchaChecked) {
      e.preventDefault();
    }
  }

  static reCaptchaCheckCallback() {
    const formButton = document.querySelector('.js-form-button');
    window.reCaptchaChecked = true;
    formButton.removeAttribute('disabled');
  }

  static reCaptchaExpiredCallback() {
    const formButton = document.querySelector('.js-form-button');
    window.reCaptchaChecked = false;
    formButton.setAttribute('disabled', true);
  }
}

export default Forms;
