import React from "react";
import { Restaurant } from "../types";
import { useRestaurantMapDispatch } from "../RestaurantMapContext";
import useLocalization from "../../Common/Localization/UseLocalization";
import { ChevronRight, CornerUpRight, X } from "react-feather";
import WindowHeading, { WindowHeadingLink } from "./WindowHeading";

interface RestaurantViewProps {
  restaurant: Restaurant;
  links: WindowHeadingLink[];
}

function RestaurantView({ restaurant, links }: RestaurantViewProps) {
  const dispatch = useRestaurantMapDispatch();
  const localization = useLocalization();

  function onClose() {
    dispatch?.setActiveRestaurant(null);
  }

  const carChargerElements = restaurant.carChargers.map((c, i) => (
    <li className="map-restaurant-details__ev-chargers__item" key={i}>
      <div className="map-restaurant-details__ev-chargers__item__keyvalue">
        <span className="map-restaurant-details__ev-chargers__item__keyvalue__value">
          {c.count}
        </span>
        st
      </div>
      <div className="map-restaurant-details__ev-chargers__item__keyvalue">
        <span className="map-restaurant-details__ev-chargers__item__keyvalue__value">
          {c.kilowatt}
        </span>
        kW
      </div>

      {localization(
        `/property/enum/carchargercompany/${c.company.toLowerCase()}`
      )}
    </li>
  ));

  const openingHoursItems = restaurant.openingHours.slice(1).map((day, i) => (
    <li
      className={`map-restaurant-details__opening-hours__item ${
        i > 3 ? "map-restaurant-details__opening-hours__item--extended" : ""
      }`}
      key={i}
    >
      <div className="map-restaurant-details__opening-hours__item__day text-truncate">
        {day.specialDayName.length > 0 ? day.specialDayName : day.dayName}
      </div>
      <div className="map-restaurant-details__opening-hours__item__hours">
        {day.opening} - {day.closing}
      </div>
    </li>
  ));

  return (
    <>
      <WindowHeading
        title={restaurant.name}
        subtitle={restaurant.streetAddress}
        links={links}
      />
      <div className="map-restaurant-details__section">
        <h4>{localization("/pages/restaurantlistpage/openinghours")}</h4>
        <ul className="map-restaurant-details__opening-hours__list">
          {openingHoursItems}
        </ul>
      </div>

      {carChargerElements.length > 0 && (
        <div className="map-restaurant-details__section">
          <h4>{localization("/pages/restaurantlistpage/ev-chargers")}</h4>
          <ul className="map-restaurant-details__ev-chargers__list">
            {carChargerElements}
          </ul>
        </div>
      )}

      <div className="map-restaurant-details__footer">
        {restaurant.latitude && restaurant.longitude && (
          <a
            href={`http://maps.google.com/maps?q=${restaurant.latitude}%2c${
              restaurant.longitude
            }&hl=${localization("countryId")}&maxname=${restaurant.name}`}
          >
            {localization("/pages/restaurantlistpage/restaurantnavigate")}
            <CornerUpRight
              color="#e41f17"
              className="map-restaurant-details__footer__icon"
            />
          </a>
        )}
        <a href={restaurant.link}>
          {localization("/pages/restaurantlistpage/restaurantexpand")}
          <ChevronRight
            color="#e41f17"
            className="map-restaurant-details__footer__icon"
          />
        </a>
      </div>
    </>
  );
}

export default RestaurantView;
