import Utilities from './utilities';
import Spinner from './spinner';
/**
 * init from product-content-tables
 * */
class IntoleranceFilter {
  constructor() {
    this.selectedIntolerances = [];
  }

  init() {
    const intoleranceFilterCheckboxes = document.querySelectorAll('.js-intolerance-filter');

    if (intoleranceFilterCheckboxes.length === 0) {
      return;
    }

    // checkbox change event
    [...intoleranceFilterCheckboxes].forEach((checkbox) => {
      checkbox.addEventListener('change', (item) => {
        // show loader
        const spinner = new Spinner('.js-spinner', '.js-spinner-results');
        spinner.toggle(true);

        setTimeout(() => {
          spinner.toggle(false);
        }, 400);

        this.filterIntolerances(item.currentTarget);
      }, true);
    });
  }

  filterIntolerances(item) {
    if (item.checked) {
      this.selectedIntolerances.push(item.name); // append
    } else {
      this.selectedIntolerances = this.selectedIntolerances
        .filter((x) => x !== item.name); // remove
    }

    // clear all previous filters and badges
    const filterCssClass = 'is-filtered';
    const filteredRows = document.querySelectorAll(`.${filterCssClass}`);
    [...filteredRows].forEach((row) => row.classList.remove(filterCssClass));

    // initially hide all badges
    const badges = document.querySelectorAll('.js-intolerance-badge');
    [...badges].forEach((badge) => badge.classList.add('d-none'));

    // loop all selected and hide matching products
    this.selectedIntolerances.forEach((intolerance) => {
      const productTables = document.querySelectorAll('.js-product-content-table');
      const selector = `input[type='checkbox'][name="${intolerance}"].js-intolerance-checkbox`;
      const matchingIntolerances = [];

      // fetch matching checkboxes
      [...productTables].forEach((table) => {
        const nodeList = table.querySelectorAll(selector);
        const filteredNodeList = [...nodeList].filter((x) => x.checked === true);
        matchingIntolerances.push(...filteredNodeList);
      });

      matchingIntolerances.forEach((checkbox) => {
        const topRow = Utilities.findAncestor(checkbox, 'tr');
        const middleRow = Utilities.getNextSibling(topRow, 'tr');
        const bottomRow = Utilities.getNextSibling(middleRow, 'tr');

        topRow.classList.add(filterCssClass);
        middleRow.classList.add(filterCssClass);
        bottomRow.classList.add(filterCssClass);
      });

      // remove product table if no visible product rows
      productTables.forEach((table) => {
        const rows = table.querySelectorAll(`tbody tr:not(.${filterCssClass})`);
        const container = Utilities.findAncestor(table, '.js-product-content-table-container');

        if (rows.length > 1) {
          container.classList.remove('d-none');
        } else {
          container.classList.add('d-none');
        }
      });

      // badge
      const badge = document.querySelector(`.js-intolerance-badge[data-intolerance="${intolerance}"]`);
      badge.classList.remove('d-none');
    });

    // show/hide information
    const hasSelectedIntoleranceFilters = document.querySelectorAll('.js-has-selected-intolerances');

    if (this.selectedIntolerances.length > 0) {
      [...hasSelectedIntoleranceFilters].forEach((x) => x.classList.remove('d-none'));
    } else {
      [...hasSelectedIntoleranceFilters].forEach((x) => x.classList.add('d-none'));
    }
  }
}

export default IntoleranceFilter;
