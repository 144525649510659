import { DateTime, Duration } from "luxon";
import { OpeningHours } from "../types";

interface OpeningHoursDateTime {
  opening: DateTime,
  closing: DateTime
}

export function getOpeningHoursAsDateTime(openingHours: OpeningHours): OpeningHoursDateTime | null {
  const date = DateTime.fromISO(openingHours.date);

  if (!openingHours.opening || !openingHours.closing) {
    return null;
  }

  const startDuration = Duration.fromISOTime(openingHours.opening);
  let endDuration = Duration.fromISOTime(openingHours.closing);

  if (!date || !startDuration || !endDuration) {
    return { opening: DateTime.now(), closing: DateTime.now() }
  }

  if (endDuration < startDuration) {
    endDuration = endDuration.plus({ day: 1 });
  }

  return { opening: date.plus(startDuration), closing: date.plus(endDuration) }
}

export function isDateTimeInsideOpeningHours(date: DateTime, openingHours: OpeningHours) {
  const o = getOpeningHoursAsDateTime(openingHours);

  if (o == null) {
    return false;
  }

  return date >= o.opening.startOf("day") && date <= o.closing
}

