/**
* Toggles visibility of dropdown element lists
*/
class Dropdown {
  static init() {
    const dropdown = document.querySelectorAll('.js-dropdown');

    Array.prototype.slice.call(dropdown).forEach((button) => {
      button.addEventListener('click', this.toggleDropdown, true);
    });
  }

  static toggleDropdown() {
    const expanded = this.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';

    this.setAttribute('aria-expanded', expanded);
    this.nextElementSibling.classList.toggle('m-dropdown__menu--expanded');
  }
}

export default Dropdown;
