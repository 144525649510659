/**
* Filter the recipes by ingredients
*/
class FilterRecipes {
  static init() {
    const selectElement = document.querySelector('.js-filter-recipes');

    if (!selectElement || selectElement.length === 0) {
      return;
    }

    selectElement.addEventListener('change', this.filterList, true);
  }

  static filterList() {
    const recipes = document.querySelectorAll('.js-recipe');
    const categories = document.querySelectorAll('.js-recipe-category');

    // Show/hide recipes
    [...recipes].forEach((recipe) => {
      if (recipe.dataset.products.includes(this.value)) {
        recipe.classList.remove('o-recipe-group__item--hidden');
        recipe.classList.add('o-recipe-group__item--shown');
      } else {
        recipe.classList.remove('o-recipe-group__item--shown');
        recipe.classList.add('o-recipe-group__item--hidden');
      }
    });

    // Show/hide categories
    [...categories].forEach((category) => {
      if (category.querySelectorAll('.o-recipe-group__item--shown').length) {
        category.classList.remove('o-recipe-group--hidden');
        category.classList.add('o-recipe-group--shown');
      } else {
        category.classList.remove('o-recipe-group--shown');
        category.classList.add('o-recipe-group--hidden');
      }
    });
  }
}

export default FilterRecipes;
