import { motion, useSpring } from "framer-motion";
import React, { ReactNode } from "react";

interface ButtonProps {
  label: string;
  icon?: JSX.ElementType;
  theme?: "outline" | "solid";
  color?: "red" | "yellow" | "blue" | "green";
  active?: boolean;
  onClick?: () => void;
  className?: string;
}

const Button = ({
  label,
  active,
  onClick,
  className,
  color,
  theme = "solid",
  icon: Icon,
}: ButtonProps) => {
  return (
    <motion.button
      whileTap={{
        scale: 0.9,
      }}
      transition={{ type: "spring", stiffness: 400, damping: 17 }}
      className={[
        "map__button",
        `map__button--${theme}`,
        `map__button--${color}`,
        active ? `map__button--active` : "",
        className ?? "",
      ].join(" ")}
      onTap={onClick}
    >
      {Icon && (
        <Icon
          className={`map__button-icon map__button-icon--${theme} map__button-icon--${color}`}
        />
      )}
      {label}
    </motion.button>
  );
};

export default Button;
