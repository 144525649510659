/**
* Toggles loader/spinner
*/
class Spinner {
  constructor(spinner, results) {
    this.spinner = document.querySelector(spinner);
    this.results = document.querySelector(results);
  }

  toggle(show) {
    if (show) {
      this.spinner.classList.add('d-block');
      this.results.classList.add('is-loading');
    } else {
      this.spinner.classList.remove('d-block');
      this.results.classList.remove('is-loading');
    }
  }
}

export default Spinner;
