import React from "react";

interface RestaurantFeatureProps {
  label: string
  icon: JSX.ElementType
}

const RestaurantFeature = ({ label, icon: Icon }: RestaurantFeatureProps) => {
  return (
    <div className="map-restaurant-list__list-item__feature">
      <div className="map-restaurant-list__list-item__feature__icon">
        <Icon />
      </div>
      {label}
    </div>
  );
}

export default RestaurantFeature;
