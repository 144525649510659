class VideoPlayer {
  static init() {
    this.initVideoPlayers();
  }

  static initVideoPlayers() {
    const players = document.querySelectorAll(".o-video-player__container");

    // Add loading class to all video players while waiting for Cookiebot consent
    players.forEach((container) => {
      container.classList.add("o-video-player__container--loading");
      this.bindConsentButton(container);
    });

    // This runs when Cookiebot has loaded and every time the consent status changes
    this.onConsentReadyEvent = window.addEventListener(
      "CookiebotOnConsentReady",
      () => {
        players.forEach((container) => {
          this.handleConsentChange(container);
        });
      }
    );

    // Time out after a time
    setTimeout(() => {
      players.forEach((container) => {
        this.handleConsentChange(container);
      });
    }, 5000);
  }

  static handleConsentChange(container) {
    container.classList.remove("o-video-player__container--loading");

    if (this.checkCookieConsent()) {
      container.classList.remove("o-video-player__container--consent-required");
      container.classList.add("o-video-player__container--ready");
      // Unsubscribe from the event
      window.removeEventListener(
        "onConsentReadyEvent",
        this.onConsentReadyEvent
      );
      this.bindVideoPlayerClick(container);
    } else {
      container.classList.add("o-video-player__container--consent-required");
    }
  }

  static bindConsentButton(container) {
    const consentButton = container.querySelector(
      ".o-video-player__consent-prompt__button"
    );
    consentButton.addEventListener("click", () => {
      if (window.Cookiebot) {
        window.Cookiebot.show();
      }
    });
  }

  static checkCookieConsent() {
    if (
      window.Cookiebot &&
      window.Cookiebot.consent &&
      window.Cookiebot.consented &&
      window.Cookiebot.consent.marketing === true
    ) {
      return true;
    }

    return false;
  }

  static bindVideoPlayerClick(container) {
    container.addEventListener(
      "click",
      () => {
        this.embedVideo(container);
      },
      true
    );
    container.addEventListener("keydown", (event) => {
      if (event.key === "Enter" || event.key === " ") {
        this.embedVideo(container);
      }
    });
  }

  static embedVideo(container) {
    if (!this.checkCookieConsent()) {
      console.error("Consent required to play video");
      return;
    }

    const thumbnail = container.querySelector("img");
    const width = thumbnail.offsetWidth;
    const height = thumbnail.offsetHeight;
    const videoUrl = container.dataset["url"];
    const idRegex = /v=([a-zA-Z0-9_-]{11})/;
    const videoId = videoUrl.match(idRegex)[1];

    const iframe = document.createElement("iframe");
    iframe.setAttribute("width", width);
    iframe.setAttribute("height", height);
    iframe.setAttribute(
      "src",
      `https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1`
    );
    iframe.setAttribute("title", "YouTube video player");
    iframe.setAttribute("frameborder", "0");
    iframe.setAttribute(
      "allow",
      "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    );
    iframe.setAttribute("referrerpolicy", "strict-origin-when-cross-origin");
    iframe.setAttribute("allowfullscreen", "");

    container.classList.add("o-video-player__container--playing");
    container.appendChild(iframe);
  }
}

export default VideoPlayer;
