import { Renderer } from "@googlemaps/markerclusterer";

function isAdvancedMarkerAvailable(map: google.maps.Map) {
  return (
    google.maps.marker &&
    map.getMapCapabilities().isAdvancedMarkersAvailable === true
  );
}

function lerp(v0: number, v1: number, t: number) {
  return v0 * (1 - t) + v1 * t;
}

const MIN_RADIUS = 40;
const MAX_RADIUS = 60;

export const renderer: Renderer = {
  render({ count, position }, stats, map) {
    // change color if this cluster has more markers than the mean cluster
    const color = "#e51e16";
    const radius = lerp(MIN_RADIUS, MAX_RADIUS, count / 50);

    // create svg literal with fill color
    const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${
      radius * 2
    } ${radius * 2}" width="${radius}" height="${radius}">
<circle cx="${radius}" cy="${radius}" opacity="1" r="${radius}" />
<text x="50%" y="60%" style="fill:#fff" text-anchor="middle" font-size="${radius}" dominant-baseline="middle" font-family="flama, Arial, sans-serif">${count}</text>
</svg>`;
    const title = `${count}`;

    // adjust zIndex to be above other markers
    const zIndex = Number(google.maps.Marker.MAX_ZINDEX) + count;
    if (isAdvancedMarkerAvailable(map)) {
      // create cluster SVG element
      const div = document.createElement("div");
      div.innerHTML = svg;
      const svgEl = div.firstElementChild;
      svgEl?.setAttribute("transform", "translate(0 25)");
      const clusterOptions = {
        map,
        position,
        zIndex,
        title,
        content: svgEl,
      };
      return new google.maps.marker.AdvancedMarkerElement(clusterOptions);
    }
    const clusterOptions = {
      position,
      zIndex,
      title,
      icon: {
        url: `data:image/svg+xml;base64,${btoa(svg)}`,
        anchor: new google.maps.Point(25, 25),
      },
    };
    return new google.maps.Marker(clusterOptions);
  },
};
