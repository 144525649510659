import { tns } from 'tiny-slider/src/tiny-slider';
/**
 * Initializes the hero image slider, mainly used on the home page
 */
class Carousel {
  static init() {
    const carouselContainer = document.querySelectorAll('.js-hero-carousel');
    const autoPlay = !!document.querySelector('.js-carousel-autoplay');

    if (carouselContainer.length === 0) {
      return;
    }

    const heroCarousel = tns({
      container: '.js-hero-carousel',
      controlsContainer: '.js-hero-controls',
      autoplay: autoPlay,
      autoplayButton: autoPlay ? '.js-carousel-autoplay' : false,
      autoPlayPosition: 'bottom',
      autoplayHoverPause: autoPlay,
      autoHeight: true,
      navPosition: 'bottom',
      rewind: true,
      navContainer: '.js-hero-carousel-indicators',
      speed: 700,
    });

    const progressBar = document.querySelector('.js-hero-progress');

    if (progressBar) {
      progressBar.classList.add('in-progress');

      // reset on index changed
      heroCarousel.events.on('indexChanged', () => {
        this.resetProgress(progressBar);
      });
    }
  }

  static resetProgress(progressBar) {
    progressBar.classList.remove('in-progress');

    setTimeout(() => {
      progressBar.classList.add('in-progress');
    }, 1000);
  }
}

export default Carousel;
