import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import RestaurantListView from "./RestaurantListView";
import {
  areFiltersReset,
  useRestaurantMap,
  useRestaurantMapDispatch,
} from "../RestaurantMapContext";
import RestaurantView from "./RestaurantView";
import useLocalization from "../../Common/Localization/UseLocalization";

interface WindowContainerProps {
  title: string;
}

const WindowContainer = ({ title }: WindowContainerProps) => {
  const { activeRestaurant, filters } = useRestaurantMap();
  const [isOpenMobile, setIsOpenMobile] = useState<boolean>(false);
  const dispatch = useRestaurantMapDispatch();
  const localization = useLocalization();

  const showClearFilters = !areFiltersReset(filters);

  const windowVariants = {
    open: { height: "calc(100vh - 80px)" },
    openDetail: { height: "auto" },
    close: { height: 150 },
  };

  const variants = {
    visible: { opacity: 1, x: 0 },
    enter: { opacity: 0, x: -300 },
    exit: { opacity: 0, x: 300 },
  };

  useEffect(() => {
    if (isOpenMobile) {
      document.body.className += " map-window-open";
      window.scrollTo(0, 0);
    } else {
      document.body.className = document.body.className.replace(
        " map-window-open",
        ""
      );
    }
  }, [isOpenMobile]);

  useEffect(() => {
    if (activeRestaurant) {
      setIsOpenMobile(true);
    }
  }, [activeRestaurant]);

  return (
    <motion.div
      initial={"close"}
      animate={
        isOpenMobile
          ? activeRestaurant == null
            ? "open"
            : "openDetail"
          : "close"
      }
      variants={windowVariants}
      className={`map-window__container`}
    >
      <AnimatePresence mode="popLayout">
        {activeRestaurant == null && (
          <motion.div
            className="map-window__view"
            transition={{ duration: 0.3, type: "tween" }}
            key="list"
            initial="enter"
            animate="visible"
            exit="exit"
            variants={variants}
          >
            <RestaurantListView
              title={title}
              links={[
                {
                  label:
                    localization("/pages/restaurantlistpage/clear-filters") ??
                    "Clear filters",
                  onClick: (e) => {
                    e.preventDefault();
                    dispatch?.resetFilters();
                  },
                  className: showClearFilters ? "" : "hidden",
                },
                {
                  label:
                    localization(
                      isOpenMobile
                        ? "/pages/restaurantlistpage/show-map"
                        : "/pages/restaurantlistpage/show-list"
                    ) ?? "Show",
                  onClick: (e) => {
                    e.preventDefault();
                    setIsOpenMobile(!isOpenMobile);
                  },
                  className: "mobile-only",
                },
              ]}
            />
          </motion.div>
        )}
        {activeRestaurant != null && (
          <motion.div
            className="map-window__view"
            transition={{ duration: 0.3, type: "tween" }}
            key={activeRestaurant.link}
            initial="enter"
            animate="visible"
            exit="exit"
            variants={variants}
          >
            <RestaurantView
              restaurant={activeRestaurant}
              links={[
                {
                  label:
                    localization("/pages/restaurantlistpage/back-to-list") ??
                    "Back to list",
                  onClick: (e) => {
                    e.preventDefault();
                    dispatch?.setActiveRestaurant(null);
                  },
                },
              ]}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default WindowContainer;
