/**
* Tabs navigation
*/
class Tabs {
  static init() {
    const tabsContainer = document.querySelectorAll('.js-tabs');

    if (tabsContainer.length === 0) {
      return;
    }

    const tabLinks = document.querySelectorAll('.js-tab-link');
    const tabPanes = document.querySelectorAll('.js-tab-pane');

    Object.keys(tabLinks).forEach((tab) => {
      tabLinks[tab].addEventListener('click', (e) => {
        this.makeInactive(tabLinks);
        this.activateTab(e);
        this.makeInactive(tabPanes);
        this.activateTabContent(e);

        e.preventDefault();
      });
    });
  }

  // makes a tab and it's content incactive
  static makeInactive(items) {
    Object.keys(items).forEach((item) => {
      items[item].classList.remove('active');
    });
  }

  // display the selected tab.
  static activateTab(e) {
    const clickedTab = e.currentTarget;
    clickedTab.classList.add('active');
  }

  // display the selected tab content.
  static activateTabContent(e) {
    // gets the element on which the event originally occurred
    const anchorReference = e.target;
    const activePaneID = anchorReference.getAttribute('href');
    const activePane = document.querySelector(activePaneID);
    activePane.classList.add('active');
  }
}

export default Tabs;
