/**
* Select video
*/
class VideoArchive {
  static init() {
    const thumbs = document.querySelectorAll('.js-video-thumbnail');

    Array.prototype.slice.call(thumbs).forEach((x) => x.addEventListener('click', () => {
      this.setActiveVideo(x);
    }, true));
  }

  static scrollToTop() {
    const pos = document.documentElement.scrollTop || document.body.scrollTop;

    if (pos > 0) {
      window.requestAnimationFrame(VideoArchive.scrollToTop);
      window.scrollTo(0, pos - pos / 8);
    }
  }

  static setActiveVideo(videoItem) {
    const videoUrl = `https://www.youtube.com/embed/${videoItem.dataset.videoId}`;
    const activeThumb = document.querySelector('.o-video-archive__thumbnail--active');
    const player = document.querySelector('.js-video-player');

    if (activeThumb) {
      activeThumb.classList.remove('o-video-archive__thumbnail--active');
    }

    videoItem.classList.add('o-video-archive__thumbnail--active');

    if (player) {
      player.src = videoUrl;
    }

    this.scrollToTop();
  }
}

export default VideoArchive;
