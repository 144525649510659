import Utilities from './utilities';
/**
* Toggles the nutrient tables between showing values per serving or per 100g
*/
class NutrientTables {
  static init() {
    const tableGroups = document.querySelectorAll('.js-nutrient-table-group');

    if (tableGroups.length === 0) {
      return;
    }

    [...tableGroups].forEach((group) => {
      const tabNavLinks = group.querySelectorAll('.js-tab-nav-link');

      [...tabNavLinks].forEach((tabNavLink) => {
        tabNavLink.addEventListener('click', this.togglePanes, true);
      });
    });
  }

  static togglePanes() {
    const { target } = this.dataset;
    const group = Utilities.findAncestor(this, '.js-nutrient-table-group');

    group.querySelector('.js-tab-nav-link.active').classList.remove('active');
    this.classList.add('active');
    group.querySelector('.js-table.active').classList.remove('active');
    group.querySelector(`#${target}`).classList.add('active');
  }
}

export default NutrientTables;
