import React from "react";
import Map from "./Map";
import { Wrapper } from "@googlemaps/react-wrapper";
import WindowContainer from "./WindowContainer";

interface RestaurantMapProps {
  googleMapsApiKey: string;
  heading: string;
}

const RestaurantMap = ({ googleMapsApiKey, heading }: RestaurantMapProps) => {
  return (
    <div className="map-wrapper__container">
      <WindowContainer title={heading} />
      <Wrapper apiKey={googleMapsApiKey}>
        <Map />
      </Wrapper>
    </div>
  );
};

export default RestaurantMap;
