interface LocationSearchInputProps {}

import React, { FormEvent, useEffect } from "react";
import useLocalization from "../../Common/Localization/UseLocalization";
import {
  useRestaurantMap,
  useRestaurantMapDispatch,
} from "../RestaurantMapContext";
import { Search } from "react-feather";

const LocationSearchInput = ({}: LocationSearchInputProps) => {
  const localization = useLocalization();
  const { filters, geolocation } = useRestaurantMap();
  const dispatch = useRestaurantMapDispatch();

  const onSearch = async function (e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const { Geocoder } = (await google.maps.importLibrary(
      "geocoding"
    )) as google.maps.GeocodingLibrary;
    const geocoder = new Geocoder();

    try {
      const request = await geocoder.geocode({
        address: filters.adress,
      });
      if (request?.results && request.results.length > 0) {
        const pos = request.results[0].geometry.location;
        dispatch?.setFocusPosition({ lat: pos.lat(), lng: pos.lng() });
        dispatch?.setGeolocation(false);
      }
    } catch {}
  };

  useEffect(() => {
    if (geolocation) {
      dispatch?.setFilters({ ...filters, adress: null });
    }
  }, [geolocation]);

  return (
    <form className="input-group" onSubmit={onSearch}>
      <input
        className="form-control map-filter__adress-input"
        type="text"
        placeholder={localization("/pages/restaurantlistpage/search")}
        onChange={(v) =>
          dispatch?.setFilters({ ...filters, adress: v.target.value })
        }
        value={filters.adress ?? ""}
      ></input>
      <button
        type="submit"
        className="input-group-append map-filter__input-group-append"
      >
        <Search stroke="currentColor" size={16} />
      </button>
    </form>
  );
};

export default LocationSearchInput;
