/**
 * Google Maps store finder
 */
class StoreFinder {
  mapMarkers = [];

  static init() {
    const settings = this.getSettings();
    const mapContainer = document.getElementById("map");
    const restaurantMapContainer = document.getElementById("restaurantMap");
    const restaurantControlsContainer = document.getElementById(
      "restaurantMapControls"
    );

    this.mapMarkers = [];

    if (settings.isRestaurantPageMap) {
      this.renderRestaurantMap(settings, restaurantMapContainer);
    }

    if (mapContainer !== null) {
      this.renderFindMaxMap(settings, mapContainer);
    }

    return false;
  }

  static renderFindMaxMap(settings, mapContainer) {
    const latlng = new window.google.maps.LatLng(
      settings.defaultLatitude,
      settings.defaultLongitude
    );
    const mapOptions = this.getMapOptions(latlng, false);
    const map = new window.google.maps.Map(mapContainer, mapOptions);

    const restList = document.getElementById("restList");
    const restaurants = restList.querySelectorAll(".js-restaurant-item");
    const locations = this.getRestaurants(restaurants);

    const submitButton = document.querySelector(".js-maps-submit")
      ? document.querySelector(".js-maps-submit")
      : false;
    const inputField = document.getElementById("mapsPlace")
      ? document.getElementById("mapsPlace")
      : false;

    if (submitButton) {
      submitButton.addEventListener("click", (e) => {
        e.preventDefault();
        this.searchAddress(map, locations);
      });
    }

    if (inputField) {
      inputField.addEventListener("keypress", (e) => {
        if (e.which === 13) {
          e.preventDefault();
          this.searchAddress(map, locations);
          e.target.blur();
        }
      });
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((e) => {
        const userPos = new window.google.maps.LatLng(
          e.coords.latitude,
          e.coords.longitude
        );
        const closestMarker =
          locations[
            this.findClosestMarker(
              locations,
              e.coords.latitude,
              e.coords.longitude
            )
          ];
        const coordinates = new window.google.maps.LatLng(
          closestMarker.lat,
          closestMarker.lon
        );
        const bounds = new window.google.maps.LatLngBounds();

        bounds.extend(userPos);
        bounds.extend(coordinates);
        map.fitBounds(bounds);

        this.getMarker(
          `${settings.markerPath}current-pos.svg`,
          userPos,
          map,
          window.MAX.translations.yourPosition,
          ""
        );

        this.sortLocations(locations, userPos);
        this.buildRestaurantsList(locations);
      });
    }

    const bounds = new window.google.maps.LatLngBounds();

    for (let i = 0; i < locations.length; i += 1) {
      const location = locations[i];
      const extnd = new window.google.maps.LatLng(location.lat, location.lon);
      bounds.extend(extnd);
    }

    const zoomChangeBoundsListener = window.google.maps.event.addListener(
      map,
      "bounds_changed",
      () => {
        window.google.maps.event.removeListener(zoomChangeBoundsListener);
        map.setZoom(Math.min(13, map.getZoom()));
      }
    );

    map.fitBounds(bounds);

    this.setMarkers(locations, map);
    this.bindMapControls(map);
  }

  static async renderRestaurantMap(settings, mapContainer) {
    const latlng = new window.google.maps.LatLng(
      settings.restLatitude,
      settings.restLongitude
    );
    const mapOptions = this.getMapOptions(latlng, true);
    const restaurantMap = new window.google.maps.Map(mapContainer, {
      ...mapOptions,
      mapId: "226b09c969746719",
    });

    const marker = await this.getMarker(
      settings.markerPath,
      latlng,
      restaurantMap,
      settings.restName,
      `<h3>${settings.restName}</h3>`
    );

    marker.setMap(restaurantMap);
  }

  static async getMarker(markerPath, latlng, map, title, html) {
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    const glyphImg = document.createElement("img");
    glyphImg.src = "/build/images/map/pin.svg";

    return new AdvancedMarkerElement({
      map,
      position: latlng,
      title,
      content: glyphImg,
    });
  }

  static getSettings() {
    const isRestaurantPageMap =
      document.getElementById("restaurantMap") !== null;
    let lat = 0;
    let lng = 0;
    let name = "";
    let theme = "max";

    if (isRestaurantPageMap) {
      lat = window.MAX.restaurant.latitude;
      lng = window.MAX.restaurant.longitude;
      name = window.MAX.restaurant.name;
      theme = window.MAX.restaurant.theme ?? theme;
    }

    return {
      markerPath: `/build/svg/logo-${theme}-small-map.svg`,
      locationMarkerPath: "/build/svg/location-map.svg",
      linkTxt: window.MAX.translations.linkText,
      noResultTxt: window.MAX.translations.noResultText,
      defaultLatitude: 18.07,
      defaultLongitude: 59.33,
      isRestaurantPageMap,
      restLatitude: lat,
      restLongitude: lng,
      restName: name,
    };
  }

  static getMapOptions(latlng, isSmallMap) {
    if (document.body.clientWidth >= 768 && !isSmallMap) {
      return {
        zoom: 14,
        center: latlng,
        panControl: !1,
        zoomControl: !0,
        scrollwheel: !1,
        scaleControl: !0,
        streetViewControl: !0,
        mapTypeControl: !0,
        clickableIcons: !1,
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      };
    }

    return {
      zoom: 14,
      center: latlng,
      panControl: !1,
      zoomControl: !0,
      scrollwheel: !1,
      zoomControlOptions: {
        style: window.google.maps.ZoomControlStyle.SMALL,
        position: window.google.maps.ControlPosition.LEFT_BOTTOM,
      },
      scaleControl: !1,
      streetViewControl: !1,
      mapTypeControl: !1,
      clickableIcons: !1,
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    };
  }

  static findClosestMarker(locations, userLat, userLng) {
    const pi = Math.PI;
    const R = 6371;
    const distances = [];
    let closest = -1;

    for (let i = 0; i < locations.length; i += 1) {
      const location = locations[i];
      const latDiff = location.lat - userLat;
      const lngDiff = location.lon - userLng;
      const latDiffDeg = latDiff * (pi / 180);
      const lngDiffDeg = lngDiff * (pi / 180);
      const userLatDeg = userLat * (pi / 180);
      const latDegrees = location.lat * (pi / 180);
      const a =
        Math.sin(latDiffDeg / 2) * Math.sin(latDiffDeg / 2) +
        Math.sin(lngDiffDeg / 2) *
          Math.sin(lngDiffDeg / 2) *
          Math.cos(userLatDeg) *
          Math.cos(latDegrees);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;

      distances[i] = d;

      if (closest === -1 || d < distances[closest]) {
        closest = i;
      }
    }

    return closest;
  }

  static getRestaurants(restaurants) {
    const restaurantsArray = [...restaurants];
    const arr = [];

    if (restaurantsArray.length > 0) {
      restaurantsArray.forEach((restaurant) => {
        arr.push({
          link: restaurant.getAttribute("href"),
          name: restaurant.querySelector(".o-restaurant-list__location h2")
            .innerHTML,
          street: restaurant.querySelector(".o-restaurant-list__location span")
            .innerHTML,
          postal: restaurant.getAttribute("data-postal"),
          city: restaurant.querySelector(".o-restaurant-list__city").innerHTML,
          hours: restaurant.querySelector(".o-restaurant-list__opening-hours")
            .innerHTML,
          lat: restaurant.getAttribute("data-coords").split(",")[0],
          lon: restaurant.getAttribute("data-coords").split(",")[1],
          fromPos: 0,
          toPos: 0,
          data: restaurant,
        });
      });
    }

    return arr;
  }

  static calcDistance(userPos, locationPos) {
    const distance =
      window.google.maps.geometry.spherical.computeDistanceBetween(
        userPos,
        locationPos
      );
    let t = "";

    if (distance < 1e3) {
      t = `${Math.round(distance)} m`;
    } else {
      t =
        distance >= 1e3 && distance < 2e3
          ? `${(distance / 1e3).toFixed(1)} km`
          : `${Math.round(distance / 1e3)} km`;
    }

    return { from: t, to: Math.round(distance) };
  }

  static compareDistance(e, o) {
    if (e.toPos === o.toPos) {
      return 0;
    }
    return e.toPos < o.toPos ? -1 : 1;
  }

  static sortLocations(locations, userPos) {
    for (let i = 0; i < locations.length; i += 1) {
      const location = locations[i];
      const locationPos = new window.google.maps.LatLng(
        location.lat,
        location.lon
      );
      const distance = this.calcDistance(userPos, locationPos);
      location.fromPos = distance.from;
      location.toPos = distance.to;
    }
    locations.sort(this.compareDistance);
  }

  static buildRestaurantsList(locations) {
    const restList = document.getElementById("restList");
    const node = restList.cloneNode(false);
    for (let i = 0; i < locations.length; i += 1) {
      const location = locations[i];
      location.data.querySelector(".o-restaurant-list__distance").innerHTML =
        location.fromPos;
      node.appendChild(location.data);
    }
    restList.parentNode.replaceChild(node, restList);
  }

  static searchAddress(map, locations) {
    const settings = this.getSettings();
    let geocoder;
    let markers = [];
    let saveTxt;
    let saveMapCenter;
    let saveMapZoom;

    if (document.getElementById("mapsPlace").value.length > 0) {
      const address = document.getElementById("mapsPlace").value;

      const findResult = document.querySelector(".js-findresult");
      saveTxt = findResult.innerText;
      saveMapCenter = map.getCenter();
      saveMapZoom = map.getZoom();

      geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        const latLng = results[0].geometry.location;

        if (status === window.google.maps.GeocoderStatus.OK) {
          const icon = {
            url: settings.locationMarkerPath,
            scaledSize: new window.google.maps.Size(30, 43),
            origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(15, 42),
          };
          const shape = {
            coords: [1, 1, 1, 43, 30, 43, 30, 1],
            type: "poly",
          };
          const marker = new window.google.maps.Marker({
            position: latLng,
            map,
            icon,
            shape,
            optimized: !1,
            title: address,
            zIndex: 1,
          });

          if (markers.length) {
            markers[0].setMap(null);
            markers = [];
          }
          markers.push(marker);

          const r =
            locations[
              this.findClosestMarker(
                locations,
                results[0].geometry.location.lat(),
                results[0].geometry.location.lng()
              )
            ];
          const i = new window.google.maps.LatLng(r.lat, r.lon);
          const p = new window.google.maps.LatLngBounds();

          p.extend(latLng);
          p.extend(i);

          const g = window.google.maps.event.addListener(
            map,
            "bounds_changed",
            () => {
              window.google.maps.event.removeListener(g);
              map.setZoom(Math.min(14, map.getZoom()));
            }
          );

          map.fitBounds(p);
          this.setMarkers(locations, map);
          this.sortLocations(locations, latLng);
          this.buildRestaurantsList(locations);

          findResult.innerText = saveTxt;
          findResult.classList.remove("alert");
        } else if (latLng === window.google.maps.GeocoderStatus.ZERO_RESULTS) {
          findResult.innerText = settings.noResultTxt;
          findResult.classList.add("alert");
        } else {
          findResult.innerText(`Geocode failed: ${latLng}`);
          findResult.classList.add("alert");
        }
      });
    } else if (saveMapCenter) {
      map.setCenter(saveMapCenter);
      map.setZoom(saveMapZoom);

      if (markers.length) {
        markers[0].setMap(null);
        markers = [];
      }
    }
  }

  static setMarkers(locations, map) {
    const settings = this.getSettings();
    const infoWindow = new window.google.maps.InfoWindow({
      content: "",
    });

    for (let i = 0; i < locations.length; i += 1) {
      const location = locations[i];

      const coordinates = new window.google.maps.LatLng(
        location.lat,
        location.lon
      );

      const marker = this.getMarker(
        settings.markerPath,
        coordinates,
        map,
        location.name,
        `<h3>${location.name}</h3>${location.street}<br>${location.postal}<br>${location.hours}<br><a href="${location.link}">${settings.linkTxt}</a>`
      );

      window.google.maps.event.addListener(marker, "click", function info() {
        infoWindow.setContent(this.html);
        infoWindow.open(map, this);
      });
    }
  }
}

export default StoreFinder;
