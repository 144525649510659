import { useContext } from "react";
import { LocalizationContext } from "./LocalizationContext";

const useLocalization = () => {
  const localization = useContext(LocalizationContext);

  return (key: string) => {
    if (localization[key]) {
      return localization[key];
    } else {
      console.warn(`Missing localization for ${key}`);
      return key;
    }
  };
};

export default useLocalization;
