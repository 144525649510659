import feather from 'feather-icons';
import Utilities from './utilities';
import Spinner from './spinner';
import IntoleranceFilter from './intolerance-filter';
import 'whatwg-fetch';

class ProductContentTables {
  static init() {
    const productContentGroups = document.querySelectorAll('.js-product-content-table-container');
    const selectElement = document.querySelector('.js-filter-product-groups');
    const expandAllRows = document.querySelector('.js-toggle-all');

    if (productContentGroups.length === 0) {
      return;
    }

    // init intolerance filter logic
    new IntoleranceFilter().init();

    [...productContentGroups].forEach((group) => {
      const toggleRows = group.querySelectorAll('.js-toggle-sub-table');
      const navLinks = group.querySelectorAll('.js-nav-link');

      [...toggleRows].forEach((toggleRow) => {
        toggleRow.addEventListener('click', () => this.toggleProductParts(toggleRow), true);
      });

      [...navLinks].forEach((navLink) => {
        navLink.addEventListener('click', this.toggleCells, true);
      });

      selectElement.addEventListener('change', this.filterProductGroups, true);
    });

    expandAllRows.addEventListener('click', () => this.toggleAll(productContentGroups), true);
  }

  /**
  * Toggles the product parts tables for all rows at once
  */
  static toggleAll(productContentGroups) {
    [...productContentGroups].forEach((group) => {
      const toggleRows = group.querySelectorAll('.js-toggle-sub-table');

      [...toggleRows].forEach((toggleRow) => {
        this.toggleProductParts(toggleRow);
      });
    });
  }

  /**
  * Toggles the product parts tables
  */
  static toggleProductParts(element) {
    const nextSibling = Utilities.getNextSibling(element, '.js-product-part-table');
    const cutter = nextSibling.querySelector('.js-product-part-cutter');
    const productReference = element.getAttribute('data-product');

    cutter.innerHTML = `<div class="a-spinner js-spinner d-block" role="status"><span class="sr-only">${window.MAX.translations.loading}</span></div>`;

    cutter.classList.toggle('o-product-content-group__cutter--expanded');

    // load product parts
    fetch(`/api/v1.0/products/${productReference}/parts/?culture=${window.MAX.settings.culture}`)
      .then((r) => r.json())
      .then((data) => {
        cutter.innerHTML = ProductContentTables.getProductPartsHtml(data);

        feather.replace();
      });
  }

  /**
  * Toggles each form between showing common or other intolerances in small devices
  */
  static toggleCells() {
    const { target } = this.dataset;
    const parent = this.closest('.js-product-content-table-container');
    const table = parent.querySelector('.js-product-content-table');
    const nonActive = target === 'common' ? 'other' : 'common';

    parent.querySelector('.js-nav-link.active').classList.remove('active');
    this.classList.add('active');
    table.classList.remove(nonActive);
    table.classList.add(target);
  }

  /**
  * Filters the product groups
  */
  static filterProductGroups() {
    // show loader
    const spinner = new Spinner('.js-spinner', '.js-spinner-results');
    spinner.toggle(true);

    setTimeout(() => {
      spinner.toggle(false);
    }, 800);

    const productGroups = document.querySelectorAll('.js-product-content-table-container');

    [...productGroups].forEach((group) => {
      const element = group;
      if (this.value && !group.classList.contains(this.value)) {
        element.style.display = 'none';
      } else {
        element.style.display = 'block';
      }
    });
  }

  /**
   * Get product parts html
   */
  static getProductPartsHtml(productParts) {
    const html = `
    <table class="o-product-content-group__table p-0">
      <tbody>
        ${productParts.map((part) => `
        <tr class="o-product-content-group__checkmarks">
          <td class="o-product-content-group__tbody-label">
            <i data-feather="corner-down-right" alt="${part.name}" class="o-product-content-group__indent-symbol"></i>
            ${part.name}
          </td>

          ${Object.entries(part.intolerances).map(([key, intolerance]) => `
          <td>
            <label class="a-checkmark">
              <input type="checkbox" name="/intolerances/${key}" ${intolerance.isIncluded ? 'checked' : null} />
              <span class="a-checkmark__symbol">
                  ${window.MAX.translations.contains}
                  ${intolerance.name}
              </span>
            </label>
          </td>`).join('')}
          ${(() => {
    if (part.ingredients !== null) {
      return `
        <tr class="o-product-content-group__ingredients">
          <td colspan="16">${part.ingredients}</td>
        </tr>
      `;
    }

    return '';
  })()}
        </tr>`).join('')}
      </tbody>
    </table>`;

    return html;
  }
}

export default ProductContentTables;
